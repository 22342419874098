var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',_vm._g(_vm._b({attrs:{"columns":_vm.columns,"rows":_vm.data,"mode":"remote","totalRows":_vm.meta.total,"isLoading":_vm.state.isLoading,"search-options":{
      enabled: false,
      trigger: 'enter',
    },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPage: _vm.params.perPage,
    }},on:{"update:isLoading":function($event){return _vm.$set(_vm.state, "isLoading", $event)},"update:is-loading":function($event){return _vm.$set(_vm.state, "isLoading", $event)},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.onSelectionChange,"on-search":_vm.refresh},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._t("row",null,null,props)]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Sayfa başına ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.params.perPage),callback:function ($$v) {_vm.$set(_vm.params, "perPage", $$v)},expression:"params.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("içerik.")]),_c('span',{staticClass:"text-nowrap pl-1"},[_vm._v("Toplam içerik: "+_vm._s(props.total)+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.params.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,true)},'vue-good-table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }