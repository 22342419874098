<template>
  <b-card>
    <div>
      <h2>Rapor Oluştur</h2>

      <b-row>
        <b-col md="4">
          <type-select v-model="form.type" />
        </b-col>
        <b-col md="4">
          <interval-picker v-model="form.interval" />
        </b-col>
        <b-col md="4">
          <start-stop-picker v-model="form.startStop" />
        </b-col>
        <b-col class="text-right">
          <b-button variant="primary" @click="createReport">
            Rapor Oluştur
          </b-button>
        </b-col>
      </b-row>
    </div>
    <hr />
    <h2 class="py-1">Sensörler</h2>
    <data-table
      :columns="columns"
      :fetch-data="getData"
      @on-selection-changed="onSelectionChange"
    >
      <template slot="row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Organization -->
        <span v-else-if="props.column.field === 'organization'">
          <span v-if="props.row.organization" class="text-nowrap">{{
            props.row.organization.name
          }}</span>
          <span v-else class="text-nowrap text-center">---</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </data-table>
  </b-card>
</template>

<script>
import DataTable from "./components/DataTable.vue";
import axios from "@axios";
import TypeSelect from "@/components/input/TypeSelect.vue";
import IntervalPicker from "@/components/input/IntervalPicker.vue";
import StartStopPicker from "@/components/input/StartStopPicker.vue";

export default {
  components: {
    DataTable,
    TypeSelect,
    IntervalPicker,
    StartStopPicker,
  },
  data() {
    return {
      filters: {},
      form: {
        type: null,
        range: null,
        interval: "day",
        startStop: {},
      },
      selectedSensors: [],
      columns: [
        {
          label: "İsim",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "İsim Filtrele",
          },
        },
        {
          label: "Organizasyon",
          field: "organization.name",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async getData(filters) {
      this.filters = filters;
      const { data } = await axios.get("/api/v1/management/sensors", {
        params: filters,
      });
      return data;
    },
    onSelectionChange(data) {
      this.selectedSensors = data.map((s) => s.id);
    },
    async createReport() {
      const {
        data: { url },
      } = await axios.get("/api/v1/management/report", {
        params: {
          sensor_ids: this.selectedSensors.join(","),
          type: this.form.type,
          interval: this.form.interval,
          ...this.form.startStop,
        },
      });
      this.download(url);
    },
    download(url) {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "rapor.xlsx";

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);
    },
  },
};
</script>

<style>
</style>