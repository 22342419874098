<template>
  <b-row>
    <b-col cols="12">
      <b-form-group label="Başlangıç">
        <b-input-group>
          <flat-pickr
            v-model="startAt"
            class="form-control"
            :config="{
              ...pickerConfig,
              maxDate: endAt,
            }"
          />
          <b-input-group-append
            is-text
            class="cursor-pointer"
            @click="() => (startAt = null)"
          >
            <feather-icon icon="XIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group label="Bitiş">
        <b-input-group>
          <flat-pickr
            v-model="endAt"
            class="form-control"
            :config="{
              ...pickerConfig,
              minDate: startAt,
            }"
          />
          <b-input-group-append
            is-text
            class="cursor-pointer"
            @click="() => (endAt = null)"
          >
            <feather-icon icon="XIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr";

export default {
  name: "StartStopPicker",
  components: { flatPickr },
  props: {
    value: { type: Object, default: {} },
  },
  data() {
    return {
      pickerConfig: {
        locale: Turkish,
      },
      startAt: null,
      endAt: null,
    };
  },
  watch: {
    startAt() {
      this.$emit("input", { startAt: this.startAt, endAt: this.endAt });
    },
    endAt() {
      this.$emit("input", { startAt: this.startAt, endAt: this.endAt });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>