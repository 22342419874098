<template>
  <b-form-group label="Tanım">
    <v-select v-model="type" :options="types" placeholder="Hepsi">
      <template #option="{ label, icon }">
        <component :is="icon" />
        <span> {{ label }}</span>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import VSelect from "vue-select";
import Walk from "vue-material-design-icons/Walk.vue";
import Bike from "vue-material-design-icons/Bike.vue";
export default {
  name: "TypeSelect",
  props: ["value"],
  components: { VSelect, Walk, Bike },
  data() {
    const types = [
      { label: "İnsan", value: "1", icon: "walk" },
      { label: "Bisiklet", value: "2", icon: "bike" },
    ];
    return {
      type: this.value ? types[value] : null,
      types: types,
    };
  },
  watch: {
    type() {
      this.$emit("input", this.type?.value);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>