<template>
  <div>
    <!-- table -->
    <vue-good-table
      v-bind="$attrs"
      v-on="$listeners"
      :columns="columns"
      :rows="data"
      mode="remote"
      :totalRows="meta.total"
      :isLoading.sync="state.isLoading"
      :search-options="{
        enabled: false,
        trigger: 'enter',
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: params.perPage,
      }"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="onSelectionChange"
      @on-search="refresh"
    >
      <template slot="table-row" slot-scope="props">
        <slot name="row" v-bind="props" />
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Sayfa başına </span>
            <b-form-select
              v-model="params.perPage"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">içerik.</span>
            <span class="text-nowrap pl-1"
              >Toplam içerik: {{ props.total }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="params.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
  },
  props: ["columns", "fetchData"],
  data() {
    return {
      state: { isLoading: false, search: "" },
      data: [],
      meta: { total: 0 },
      rows: [],
      params: {
        filters: {},
        sort: null,
        page: 1,
        perPage: 10,
      },
    };
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  methods: {
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
      this.params.page = newProps.page;
    },
    onPageChange(params) {
      this.params.page = params.currentPage;
    },
    onPerPageChange(params) {
      this.params.perPage = params.currentPerPage;
    },
    onSortChange(params) {
      if (params.length == 0) {
        this.params.sort = null;
      } else {
        this.params.sort = `${params[0].field},${params[0].type}`;
      }
    },
    onColumnFilter(params) {
      this.params.filters = params.columnFilters;
    },
    onSelectionChange({ selectedRows }) {
      this.$emit("on-selection-changed", selectedRows);
    },
    async refresh() {
      this.state.isLoading = true;
      try {
        const { data, meta } = await this.fetchData({
          ...this.params.filters,
          sort: this.params.sort,
          page: this.params.page,
          perPage: this.params.perPage,
        });
        this.data = data;
        this.meta.total = meta.total;
      } catch (e) {
      } finally {
        this.state.isLoading = false;
      }
    },
  },
  async mounted() {
    this.refresh();
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";

table.vgt-table td {
  vertical-align: middle !important;
}
</style>
