<template>
  <b-form-group label="Zaman Aralığı">
    <v-select
      v-model="interval"
      :options="intervals"
      placeholder="Hepsi"
      :clearable="false"
    />
  </b-form-group>
</template>

<script>
import VSelect from "vue-select";

export default {
  name: "IntervalPicker",
  components: { VSelect },
  props: {
    value: {
      type: String,
      required: false,
      default: "day",
    },
  },
  data() {
    const intervals = [
      { label: "Gün", value: "day", timespan: 15 },
      { label: "Ay", value: "month", timespan: 12 },
      { label: "Yıl", value: "year", timespan: 1 },
    ];
    return {
      interval: intervals.find((i) => i.value == this.value),
      intervals,
    };
  },
  watch: {
    interval() {
      this.$emit("input", this.interval.value);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>